.container {
  width: 800px;
}

.dropzone {
  height: 100vh;
}

.grid-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.grid-item-content {
  box-sizing: border-box;
  /* background: #08e; */
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  border-radius: 0.5em;
}
